import React from "react";
import LoaderCrehub from "../../../images/Credhub-Loader.gif";
const Loader = () => {
  return (
    <div className="credhub-loader">
      <div className="text-center" style={{ marginTop: "17%" }}>
        {/* <img src={LoaderCrehub} width="150px" /> */}
      </div>
      {/* <div className='loader-component'>
                <i className='fa fa-circle-o-notch fa-spin' />
                <span>Please wait...</span>
            </div> */}
    </div>
  );
};

export default Loader;
